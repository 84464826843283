import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destination']

  updateDestination(event) {
    this.destinationTarget.value = this.determineValue(event.target)
  }

  determineValue(input) {
    if (input.dataset.inputSource === 'label') {
      // This is necessary because the value of an option is an ID, but we want the email address
      // which is in the label in this format: 'First Lastname <email@address>'
      const label = input.options[input.selectedIndex].label
      return label?.match(/<(.+)>/)?.[1]
    }

    return input.value
  }
}