import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    super.connect();

    this.select = new TomSelect(this.inputTarget, {
      score: search => {
        return item => {
          return 1
        }
      },
      valueField: 'id',
      labelField: 'name_and_email',
      highlight: false,
      delimiter: null,
      create: false,
      load: this.loadData.bind(this),
      render: {
        option: this.optionRenderer.bind(this),
        item: this.optionRenderer.bind(this)
      },
      onChange: this.onChangeHandler.bind(this),
      onFocus: () => { this.select.clear() }
    });
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
    super.disconnect();
  }

  loadData(query, callback) {
    this.select.clearOptions()

    fetch(`/customer_users/search/${encodeURIComponent(query)}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(data => callback(data))
  }

  optionRenderer(data, escape) {
    return `<div>
      <strong>${escape(data.name)}</strong>
      <em>${escape(data.email)}</em>
    </div>`
  }

  onChangeHandler(value) {
    const selected = this.select.options[value]

    if (selected) {
      this.dispatch('option-selected')
    }
  }
}
