import { Controller } from "stimulus";

/**
 * Connects to data-controller="click-to-replace"
 *
 * Send contents of an element to the target element
 */
export default class extends Controller {
  static targets = ["item"];

  static values = {
    targetSelector: String
  };

  connect() {
    this.itemTargets.forEach(itemTarget => {
      itemTarget.addEventListener("click", this.replace.bind(this));
    });
  }

  replace(event) {
    const targetElement = document.querySelector(this.targetSelectorValue);

    if (targetElement === null) {
      return;
    }

    let replaceEvent = new CustomEvent("replace-content", {
      detail: { content: event.target.dataset.content }
    });

    targetElement.dispatchEvent(replaceEvent);
  }
}
