import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'objectBrand', 'objectType', 'lecturaManufacturerId', 'lecturaObjectId'
  ]

  static values = {
    manufacturers: Array
  }

  connect() {
    super.connect();

    const $instance = this
    const optionCreate = function(data, escape) {
      return `<div class="create option">Handmatig: ${escape(data.input)}</div>`;
    };

    this.objectBrandSelect = new TomSelect(this.objectBrandTarget, {
      plugins: ['change_listener'],
      valueField: "name",
      labelField: "name",
      searchField: ["name"],
      maxItems: 1,
      selectOnTab: true,
      createOnBlur: true,
      create: true,
      persist: true,
      highlight: false,
      preload: true,
      render: { option_create: optionCreate },
      onInitialize: function() {
        this.initialValue = this.getValue();
      },
      onChange: function(newValue) {
        let valueChanged = this.initialValue !== newValue;

        if (valueChanged) {
          let selected = this.options[newValue];

          if (selected) {
            $instance.lecturaManufacturerIdTarget.value = selected.id;
          }
        } else {
          $instance.lecturaManufacturerIdTarget.value = this.initialValue;
        }
      },
      onFocus: function() {
        const value = this.getValue();
        if (value.length > 0) {
          this.control_input.style.opacity = '1'
          this.control_input.style.width = '100%'
          this.control_input.style.position = 'relative'
          this.clear(true);
          this.control_input.value = value;
          this.control_input.focus();
        }
        $instance.objectBrandTarget.tomselect.clearOptions();
        $instance.objectBrandTarget.tomselect.refreshOptions(true);
      },
      load: function(query, callback) {
        callback($instance.manufacturersValue);
      }
    });

    this.objectTypeSelect =  new TomSelect(this.objectTypeTarget, {
      valueField: "model_name",
      labelField: "model_name",
      searchField: ["search_term", "model_name"],
      maxItems: 1,
      selectOnTab: true,
      createOnBlur: true,
      create: true,
      persist: true,
      highlight: false,
      preload: "focus",
      render: { option_create: optionCreate },
      onInitialize: function() {
        this.initialValue = this.getValue();
      },
      onChange: function(newValue) {
        let valueChanged = this.initialValue !== newValue;

        if (valueChanged) {
          let selected = this.options[newValue];

          if (selected) {
            $instance.lecturaObjectIdTarget.value = selected.id;
          }
        } else {
          $instance.lecturaObjectIdTarget.value = this.initialValue;
        }
      },
      onFocus: function() {
        const value = this.getValue();

        if (value.length > 0) {
          this.control_input.style.opacity = '1'
          this.control_input.style.width = '100%'
          this.control_input.style.position = 'relative'
          this.clear(true);
          this.control_input.value = value;
          this.control_input.focus();
        }
        $instance.objectTypeTarget.tomselect.clearOptions();
        $instance.objectTypeTarget.tomselect.refreshOptions(true);
      },
      load: function(query, callback) {
        if (!$instance.objectBrandTarget.value || !$instance.lecturaManufacturerIdTarget.value) return callback();

        $instance.lecturaSearches($instance.objectBrandTarget.value, query).then((data) => {
          callback(data)
        })
      }
    });
  }

  disconnect() {
    if (this.objectBrandSelect) {
      this.objectBrandSelect.destroy()
    }

    if (this.objectTypeSelect) {
      this.objectTypeSelect.destroy()
    }

    super.disconnect();
  }

  async lecturaSearches(brand, query) {
    const baseUri = `${location.protocol}//${location.host}`;
    const uri = `${baseUri}/lectura_searches/objects/${encodeURIComponent(`${brand} ${query}`)}`
    const response = await fetch(uri)

    return response.json()
  }
}
