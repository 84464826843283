import { Controller } from 'stimulus'
import { csrfToken } from "./mixins/csrf_token";

export default class extends Controller {
  static targets = ['loader', 'purchasePrice', 'returnOnEquityPercentage', 'monthlyPayment', 'maxLgdPercentage']

  connect() {
    csrfToken(this)
  }

  updateFields() {
    const purchasePrice = this.purchasePriceTarget.value

    // Is not a valid purchase price yet
    if (!purchasePrice) return

    // Is not a valid purchase price yet e.g. 6, or 6.
    if (purchasePrice.slice(-1) === ',' || purchasePrice.slice(-1) === '.') return

    clearTimeout(this.typingTimeout)

    this.typingTimeout = setTimeout(() => {
      this.toggleLoader()

      fetch(this.data.get('path'), {
        method: this.data.get('method'),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': this.csrfToken,
        },
        body: JSON.stringify({ purchase_price: this.purchasePriceTarget.value })
      })
        .then((response) => response.json())
        .then((data) => {
          this.returnOnEquityPercentageTarget.innerHTML = data.decorated_return_on_equity_percentage
          this.monthlyPaymentTarget.innerHTML = data.decorated_monthly_payment
          this.maxLgdPercentageTarget.innerHTML = data.decorated_max_lgd_percentage
          this.toggleLoader()
        })
    }, 800);
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle('hidden')
    this.returnOnEquityPercentageTarget.classList.toggle('hidden')
    this.monthlyPaymentTarget.classList.toggle('hidden')
    this.maxLgdPercentageTarget.classList.toggle('hidden')
  }
}
