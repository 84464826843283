// Part of this implementation is based on the example given on the https://selectize.github.io/selectize.js/ website
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    collection: Array
  }

  connect() {
    this.emailRegex = "([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)";
    const input = this.inputTarget
    const options = this.collectionValue.map(option => {
      return { name: option[0], email: option[1] };
    });

    this.select = new TomSelect(input, {
      persist: false,
      maxItems: 10,
      valueField: "email",
      labelField: "name",
      searchField: ["name", "email"],
      options: options,
      render: {
        item: this.renderItem.bind(this)
      },
      createFilter: this.createFilter.bind(this),
      create: this.createItem.bind(this)
    });
  }

  disconnect() {
    // Destroy TomSelect
    if (this.select) {
      this.select.destroy()
    }
  }

  renderItem(item, escape) {
    if (item.name) {
      return `<div>${escape(item.name)} (${escape(item.email)})</div>`;
    } else {
      return `<div>${escape(item.email)}</div>`;
    }
  }

  createFilter(input) {
    let match, regex;

    // email@address.com
    regex = new RegExp(`^${this.emailRegex}$`, "i");
    match = input.match(regex);
    if (match) return !this.select.options.hasOwnProperty(match[0]);

    // name <email@address.com>
    regex = new RegExp(`^([^<]*)<${this.emailRegex}>$`, "i");
    match = input.match(regex);
    if (match) return !this.select.options.hasOwnProperty(match[2]);

    return false;
  }

  createItem(input) {
    if (new RegExp(`^${this.emailRegex}$`, "i").test(input)) {
      return { email: input };
    }

    let match = input.match(new RegExp(`^([^<]*)<${this.emailRegex}>$`, "i"));
    if (match) {
      return {
        email: match[2],
        name: match[1].trim()
      };
    }
    alert("Invalid email address.");
    return false;
  }
}
