import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['refreshButton', 'loader', 'frame']

  update() {
    this.loaderTarget.classList.toggle('hidden')
    this.frameTarget.classList.toggle('hidden')
    this.refreshButtonTarget.click()
  }
}
