import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    data: Object
  }

  connect() {
    this.createChart()
  }

  disconnect() {
    this.element.querySelector('canvas').remove()
  }

  generateLabels() {
    return Object.values(this.dataValue['dates'])
  }

  getDataFor(serie) {
    return Object.values(this.dataValue[serie]).map((values, date) => {
      return values && values['uncapped_lgd']
    })
  }

  labelCallback(item) {
    const sets = [
      this.dataValue['max_lgd_point'],
      this.dataValue['resulting'],
      this.dataValue['organization'],
      this.dataValue['deal']
    ]

    const data = sets[item.datasetIndex][item.index]

    if (data) {
      return `${accounting.formatMoney(item.yLabel, '€', 0, '.', ',')} (${data.lgd_percentage}%)`
    }
  }

  createChart() {
    const chartInstance = new Chart(
      $('<canvas/>')
        .height(300)
        .appendTo(this.element)[0],
      {
        type: 'line',
        data: {
          labels: this.generateLabels(),
          datasets: [
            {
              label: 'Max LGD punt (Deal + Klant)',
              data: this.getDataFor('max_lgd_point'),
              borderColor: '#F89A1C',
              backgroundColor: '#F89A1C',
              pointRadius: 4,
              fill: false
            },
            {
              label: 'Deal + Klant',
              data: this.getDataFor('resulting'),
              borderColor: 'black',
              backgroundColor: 'black',
              fill: false
            },
            {
              label: 'Klant',
              data: this.getDataFor('organization'),
              borderColor: '#aaaaaa',
              backgroundColor: '#aaaaaa',
              fill: false
            },
            {
              label: 'Deal',
              data: this.getDataFor('deal'),
              borderColor: '#fbc67f',
              backgroundColor: '#fbc67f',
              hidden: true,
              fill: false,
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          elements: { line: { tension: 0 } },
          tooltips: {
            mode: 'x',
            callbacks: {
              label: this.labelCallback.bind(this)
            }
          },
          legend: {
            display: true,
            labels: {
              generateLabels: function(chart) {
                const datasets = chart.data.datasets
                // Reverse the index of the legend labels because we want to show "the Max LGD punt"
                // on the right side of the legend but on top of the other charts
                return [3, 2, 1, 0].map(index => {
                  const dataset = datasets[index]
                  return {
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor,
                    strokeStyle: dataset.borderColor,
                    lineWidth: dataset.borderWidth,
                    hidden: dataset.hidden,
                    index: index,
                    datasetIndex: index
                  }
                })
              }
            },
            onClick: function(e, legendItem) {
              const dataSet = chartInstance.data.datasets[legendItem.datasetIndex]
              dataSet.hidden = !dataSet.hidden
              dataSet._meta.hidden = dataSet.hidden
              chartInstance.update()
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => {
                    return accounting.formatMoney(value, '€', 2, '.', ',')
                  }
                }
              }
            ]
          }
        }
      }
    )
  }
}

