import { Controller } from "stimulus";

/**
 * Connects to data-controller="selectable-variables"
 *
 * Allows looping through variables indicated between `[` and `]`
 *
 * CTRL + Enter: Select the next variable while in the textarea
 */
export default class extends Controller {
  static targets = ["textarea"];
  index = 0;
  variables = [];
  text = "";

  connect() {
    this.reset();

    this.textareaTarget.addEventListener("keyup", this.onKeyUp.bind(this));

    // When dynamically replacing the contents of the textarea,
    // make sure you use this event. This will replace the contents and reset the controller.
    this.textareaTarget.addEventListener(
      "replace-content",
      this.replaceContent.bind(this)
    );
  }

  reset() {
    this.index = 0;
    this.variables = [];
    this.text = "";

    this.parse();
  }

  replaceContent(event) {
    this.textareaTarget.focus();
    this.textareaTarget.value = event.detail.content;
    this.reset();
  }

  parse() {
    this.text = this.textareaTarget.value;
    this.variables = this.text.match(/(\[.*?])/g);

    if (this.variables === null) {
      this.variables = [];
    }
  }

  selectNextVariable() {
    this.parse();

    if (this.variables.length === 0) {
      return;
    }

    this.textareaTarget.focus();

    this.textareaTarget.selectionStart = this.text.indexOf(
      this.variables[this.index],
      0
    );

    this.textareaTarget.selectionEnd =
      this.text.indexOf(this.variables[this.index]) +
      this.variables[this.index].length;

    this.index++;

    if (this.index >= this.variables.length) {
      this.index = 0;
    }
  }

  onKeyUp(event) {
    if (event.keyCode === 13 && event.ctrlKey) {
      this.selectNextVariable();
    }
  }
}
