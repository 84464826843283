import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    data: Object
  };

  connect() {
    this.createChart();
  }

  disconnect() {
    this.element.querySelector("canvas").remove();
  }

  valueLineBlank(valueLine) {
    return valueLine === null || valueLine.every(value => value === 0);
  }

  valueLinePresent(valueLine) {
    return !this.valueLineBlank(valueLine);
  }

  createChart() {
    new Chart(
      $("<canvas/>")
        .height(300)
        .appendTo(this.element)[0],
      {
        type: "line",
        data: {
          labels: this.dataValue["labels"],
          datasets: [
            {
              label: "Exposure",
              data: this.dataValue["outstanding_ends"],
              borderColor: "#505050",
              backgroundColor: "#505050",
              fill: false,
              hidden: this.valueLinePresent(
                this.dataValue["exposure_at_defaults"]
              )
            },
            {
              label: "EAD (Exposure at default)",
              data: this.dataValue["exposure_at_defaults"],
              borderColor: "#000000",
              backgroundColor: "#000000",
              fill: false,
              hidden: this.valueLineBlank(
                this.dataValue["exposure_at_defaults"]
              )
            },
            {
              label: "FMV (Fair market value)",
              data: this.dataValue["fair_market_values"],
              borderColor: "#AAA",
              backgroundColor: "#AAA",
              fill: false
            },
            {
              label: "RCV (Realizable collateral value)",
              data: this.dataValue["realizable_collateral_values"],
              borderColor: "#F89A1C",
              backgroundColor: "#F89A1C",
              fill: false
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          elements: { line: { tension: 0 } },
          tooltips: {
            mode: "x",
            callbacks: {
              label: item => {
                return accounting.formatMoney(item.yLabel, "€", 2, ".", ",");
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => {
                    return accounting.formatMoney(value, "€", 2, ".", ",");
                  }
                }
              }
            ],
            xAxes: [
              {
                ticks: {
                  callback: (value, index) => {
                    return index % 3 === 0 ? value : "";
                  }
                }
              }
            ]
          }
        }
      }
    );
  }
}
