import { Controller } from 'stimulus'
import { csrfToken } from "./mixins/csrf_token";

export default class extends Controller {
  static targets = ['loader', 'interestPercentage', 'returnOnEquityPercentage', 'monthlyPayment']

  connect() {
    csrfToken(this)
  }

  updateFields() {
    const interestPercentage = this.interestPercentageTarget.value

    // Is not a valid percentage yet
    if (!interestPercentage) return

    // Is not a valid percentage yet e.g. 6,
    if (interestPercentage.slice(-1) === ',') return

    clearTimeout(this.typingTimeout)

    this.typingTimeout = setTimeout(() => {
      this.toggleLoader()

      fetch(this.data.get('path'), {
        method: this.data.get('method'),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': this.csrfToken,
        },
        body: JSON.stringify({ interest_percentage: this.interestPercentageTarget.value })
      })
        .then((response) => response.json())
        .then((data) => {
          this.returnOnEquityPercentageTarget.innerHTML = data.decorated_return_on_equity_percentage
          this.monthlyPaymentTarget.innerHTML = data.decorated_monthly_payment
          this.toggleLoader()
        })
    }, 800);
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle('hidden')
    this.returnOnEquityPercentageTarget.classList.toggle('hidden')
    this.monthlyPaymentTarget.classList.toggle('hidden')
  }
}
