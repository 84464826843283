import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-hide-disable"
// This controller allows you to disable and hide an input based on the changing value of another
// Define the trigger checkbox using a data-toggle-disable-target property with the value of 'trigger',
// and de target checkbox using the data-toggle-disable-target value of 'toggleDisable'
export default class extends Controller {
    static targets = ['hideCheckbox', 'checkbox', 'hideSelect', 'select']

    connect() {
        this.handleCheckboxTrigger()
        this.handleSelectTrigger()
    }

    handleCheckboxTrigger() {
        if (this.checkboxTarget.checked) {
            this.selectTarget.disabled = true;
            this.hideSelectTarget.classList.add('hidden')
        } else {
            this.selectTarget.disabled = false;
            this.hideSelectTarget.classList.remove('hidden')
        }
    }

    handleSelectTrigger() {
        if (this.selectTarget.value !== '') {
            this.checkboxTarget.disabled = true;
            this.hideCheckboxTarget.classList.add('hidden')
        } else {
            this.checkboxTarget.disabled = false;
            this.hideCheckboxTarget.classList.remove('hidden')
        }
    }
}
