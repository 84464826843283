import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['openedIcon', 'closedIcon', 'content']
  static values = {
    key: String,
    rememberToggled: String
  }

  connect() {
    let startOpen;

    if (this.rememberToggledValue !== '') {
      let cookie = this.getCookie(this.rememberToggledValue);

      if (cookie.includes(this.keyValue)) {
        startOpen = true;
      }
    }

    this.contentTargets.forEach((target) => {
      if (target.querySelector('.hidable-unfold-on-connect')) {
        startOpen = true;
      }
    });

    if (startOpen) {
      this.show();
    }
  }

  toggleVisibility() {
    this.toggle()

    if (this.rememberToggledValue !== '') {
      let cookie = this.getCookie(this.rememberToggledValue);
      if (cookie.includes(this.keyValue)) {
        cookie = cookie.filter((name) => {
          return name !== this.keyValue
        })
      } else {
        cookie.push(this.keyValue)
      }

      this.setCookie(cookie)
    }
  }

  toggle() {
    if (this.hasOpenedIconTarget && this.hasClosedIconTarget) {
      this.openedIconTarget.classList.toggle('hidden')
      this.closedIconTarget.classList.toggle('hidden')
    }

    this.contentTargets.forEach((target) => {
      target.classList.toggle('hidden')
    })
  }

  show(){
    if (this.hasOpenedIconTarget && this.hasClosedIconTarget) {
      this.openedIconTarget.classList.remove('hidden')
      this.closedIconTarget.classList.add('hidden')
    }

    this.contentTargets.forEach((target) => {
      target.classList.remove('hidden')
    })
  }

  setCookie(value) {
    let date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    document.cookie = `${this.rememberToggledValue}=${JSON.stringify(value)}; expires=${date.toUTCString()}`;
  }

  getCookie(name) {
    name = name + '=';

    const decodedCookie = decodeURIComponent(document.cookie);

    // Get all cookies, split on ; sign
    const cookies = decodedCookie.split(';');

    // Loop over the cookies
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.indexOf(name) === 0) {
        return JSON.parse(cookie.substring(name.length, cookie.length));
      }
    }

    return []
  }
}
