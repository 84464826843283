import { Controller } from "stimulus";
import { csrfToken } from "./mixins/csrf_token";

/**
 * Temporary controller for Sortable.
 *
 * Replace by stimulus-sortable when moved to the new asset pipeline.
 * At the moment, stimulus-sortable needs to many extra dependencies to make it a viable replacement for now.
 * Once we have the new asset pipeline, this will resolve itself.
 **/
export default class extends Controller {
  static targets = ["handle"];
  static values = {
    updatePath: String
  };

  connect() {
    csrfToken(this);

    const body = this.element.querySelector("tbody");

    Sortable.create(body, {
      handle: ".handle",
      ghostClass: "invisible",
      onSort: async () => {
        const sorted = Array.from(body.querySelectorAll("tr")).map(
          row => row.dataset.id
        );

        try {
          let response = await fetch(this.updatePathValue, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.csrfToken
            },
            body: JSON.stringify({ ids: sorted })
          });

          if (!response.ok) {
            throw new Error("Fout bij het opslaan van sortering");
          }
        } catch (error) {
          alert(error.message);
        }
      }
    });
  }
}
