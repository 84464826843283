import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import { csrfToken } from './mixins/csrf_token'

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ['form', 'fileField', 'dropzone', 'progressBarContainer']

  connect() {
    csrfToken(this)
    this.setupDropzone()
  }

  setupDropzone() {
    const url = this.formTarget.action
    const method = this.formTarget.querySelector("input[name='_method']").value
    const paramName = this.fileFieldTarget.name
    const dropzone = this.dropzoneTarget
    const progressBarContainer = this.progressBarContainerTarget
    const progressBar = this.progressBarContainerTarget.querySelector(".progress-bar")
    const modalCloseButtons = document.querySelectorAll(".hidden-modal-close")

    new Dropzone(dropzone, {
      url: url,
      method: method,
      paramName: paramName,
      uploadMultiple: true,
      parallelUploads: 10,
      maxFilesize: 25 * 1024 * 1024, // Should match client_max_body_size in nginx config
      accept: function(file, done) {
        if (file.size > this.options.maxFilesize) {
          alert("Het bestand is groter dan 25MB en kan niet worden geüpload")
        } else {
          done();
        }
      },
      withCredentials: true,
      headers: {
        'Accept': 'text/vnd.turbo-stream.html,text/html',
        'X-CSRF-Token': this.csrfToken
      },
      disablePreviews: true,
      uploadprogress: function (_file, progress, _bytesSent) {
        dropzone.classList.add('hidden')
        progressBarContainer.classList.remove('hidden')
        progressBar.style.width = progress + "%"
      },
      success: function (response) {
        modalCloseButtons.forEach((modalCloseButton) => {
          modalCloseButton.click()
        })

        Turbo.renderStreamMessage(response.xhr.response)
      },
      error: function (response) {
        modalCloseButtons.forEach((modalCloseButton) => {
          modalCloseButton.click()
        })

        Turbo.renderStreamMessage(response.xhr.response)
      },
    });
  }
}
