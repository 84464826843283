import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'valueOfValueField']

  updateField(event) {
    event.preventDefault()

    const value = event.params.value

    if (!value && value !== 0) return

    this.valueOfValueFieldTarget.value = value

    // Ensures that listeners on the field e.g. other Stimulus controllers or
    // legacy JS components are aware that there's a change
    const newEvent = new Event('change', { "bubbles": true, "cancelable": false });
    this.valueOfValueFieldTarget.dispatchEvent(newEvent)
  }
}
