import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'checkboxAll', 'checkbox' ]

    connect() {
        this.refresh()
    }

    toggleAll() {
        this.checkboxTargets.forEach((checkbox) => {
            checkbox.checked = this.checkboxAllTarget.checked
        })
    }

    refresh() {
        let checkboxesCount = this.checkboxTargets.length
        let checkboxesCheckedCount = this.checkboxTargets.filter((el) => el.checked).length

        this.checkboxAllTarget.checked = checkboxesCheckedCount > 0 && checkboxesCheckedCount === checkboxesCount
        this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
    }
}
