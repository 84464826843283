import { Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['indicator', 'autoAnswer', 'userIcon', 'userNotSavedIcon', 'autoIcon']
  static values = {
    initialKlass: { type: String, default: 'text-muted' },
    trueKlass: { type: String, default: 'text-success' },
    falseKlass: { type: String, default: 'text-danger' }
  }

  update(event) {
    this.indicatorTarget.classList.remove(this.initialKlassValue, this.trueKlassValue, this.falseKlassValue)

    let value = event.target.value
    let klass = value === 'true'
      ? this.trueKlassValue
      : value === 'false'
      ? this.falseKlassValue
      : this.initialKlassValue

    this.indicatorTarget.classList.add(klass)

    if (this.hasAutoAnswerTarget) {
      const autoAnswerValue = this.autoAnswerTarget.dataset.autoAnswerValue

      this.userIconTarget.classList.add('hidden')
      this.autoIconTarget.classList.add('hidden')
      this.userNotSavedIconTarget.classList.add('hidden')

      if (value.toString() === autoAnswerValue.toString()) {
        this.autoIconTarget.classList.remove('hidden')
      } else {
        this.userNotSavedIconTarget.classList.remove('hidden')
      }
    }
  }
}
