import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    path: String
  }

  static tomselectOptions = {
    people: false,
    organizations: true,
    disable_remote: false
  }

  connect() {
    super.connect();

    this.select = new TomSelect(this.inputTarget, {
      score: search => {
        return item => {
          return 1
        }
      },
      plugins: ['restore_on_backspace'],
      valueField: 'id',
      labelField: 'name',
      highlight: false,
      delimiter: null,
      create: false,
      load: this.loadData.bind(this),
      render: {
        option: this.optionRenderer.bind(this),
        item: this.optionRenderer.bind(this)
      },
      onChange: this.onChangeHandler.bind(this),
      onFocus: () => { this.select.clear() }
    });
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }

    super.disconnect();
  }

  optionRenderer(data, escape) {
    return `<div>
      <strong>${escape(data.name)}</strong>
      ${
      data.reference
        ? `<small class="text-primary">${data.reference}</small>`
        : `<small class="text-info">${data.coc_number}</small>`
    }
    </div>`;
  }

  loadData(query, callback) {
    if (!query.length) {
      return callback()
    }
    this.select.clearOptions()

    const queryString = new URLSearchParams(this.constructor.tomselectOptions).toString();
    fetch(`${this.pathValue}/${encodeURIComponent(query)}?${queryString}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.json())
      .then(data => { callback(data); })
  }

  onChangeHandler(value) {
    const selected = this.select.options[value]

    if (!selected && selected.justCreated) {
      return
    }

    const url = selected.reference ? `/organizations/${selected.id}` : `/organizations/new?coc_number=${selected.id}`
    Turbo.visit(url)
  }
}
