import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'results', 'loader', 'query']

  connect() {
    if (this.queryTarget.value !== '') {
      this.updateResults()
    }
  }

  disableSubmit(event) {
    event.preventDefault()
  }

  updateResults() {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => {
      this.toggleLoader()

      const formRequest = new XMLHttpRequest();
      const formData = new FormData(this.formTarget);

      formRequest.open(this.formTarget.method, this.formTarget.action)
      formRequest.onload = () => {
        this.resultsTarget.innerHTML = formRequest.response
        this.toggleLoader()
      }
      formRequest.send(formData);
    }, 500);
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle('hidden')
    this.resultsTarget.classList.toggle('hidden')
  }
}
