import { Controller } from "stimulus";

// Use this Stimulus controller to submit a form using 'form'. Controller has
// two optional features:
//  1. You can show and hide an loader during the request-response cycle using 'loader' and 'frame'
//  2. You can specify an alternative URL (action) for the form e.g. when
//     when there's another endpoint to 'refresh' versus submitting using 'alternativeSubmitUrl'
export default class extends Controller {
  static targets = ['form', 'loader', 'frame']

  static values = {
    alternativeSubmitUrl: String,
  }

  submitForm(event) {
    event.preventDefault()

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.toggle('hidden')
      this.frameTarget.classList.toggle('hidden')
    }

    const form = this.formTarget

    if (this.alternativeSubmitUrlValue) {
      form.action = this.alternativeSubmitUrlValue
    }

    form.requestSubmit()
  }
}
