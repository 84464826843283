import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    data: Object
  }

  connect() {
    this.createChart()
  }

  disconnect() {
    this.element.querySelector('canvas').remove()
  }

  generateLabels() {
    return Object.values(this.dataValue).flatMap((terms) => {
      return terms.map((term) => {
        return term.date
      })
    })
  }

  getDataFor(terms) {
    return terms.map((term) => {
      return {
        y: term.uncapped_lgd,
        x: term.date
      }
    })
  }

  labelCallback(item) {
    const dataSet = Object.values(this.dataValue)[item.datasetIndex]
    const data = dataSet[item.index]
    return `${accounting.formatMoney(item.yLabel, '€', 0, '.', ',')} (${data.lgd_percentage}%)`
  }

  generateDataSets() {
    return Object.keys(this.dataValue).map((title) => {
      const terms = this.dataValue[title];
      return {
        label: title,
        data: this.getDataFor(terms),
        borderColor: 'black',
        backgroundColor: 'black',
        pointRadius: 4,
        fill: false,
      }
    })
  }

  createChart() {
    new Chart(
      $('<canvas/>')
        .height(300)
        .appendTo(this.element)[0],
      {
        type: 'line',
        data: {
          labels: this.generateLabels(),
          datasets: this.generateDataSets(),
        },
        options: {
          maintainAspectRatio: false,
          elements: { line: { tension: 0 } },
          tooltips: {
            mode: 'x',
            callbacks: {
              label: this.labelCallback.bind(this)
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => {
                    return accounting.formatMoney(value, '€', 2, '.', ',')
                  }
                }
              }
            ]
          }
        }
      }
    )
  }
}

