import { Controller } from "stimulus";

// Connects to data-controller="layout"
export default class extends Controller {
  static targets = ["bar"];

  toggleBar(event) {
    const icon = event.target.querySelector("i");

    if (icon) {
      icon.classList.toggle("rotate-180");
    }

    this.barTarget.classList.toggle("toggle-display");
  }
}
