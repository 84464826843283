import { Controller } from "stimulus";
import { DirectUpload } from "activestorage";

export default class extends Controller {
  connect() {
    this.input = this.element.querySelector("input");
    this.formHint = this.element.querySelector(".form-hint");

    this.onSubmit = this.handleSubmit.bind(this);
    this.input.form.addEventListener("submit", this.onSubmit);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.uploading) {
      this.start();
    }

    return false;
  }

  directUploadWillStoreFileWithXHR(request) {
    this.input.style.display = "none";
    if (this.formHint) {
      this.formHint.style.display = "none";
    }

    this.createProgressBar();

    request.upload.addEventListener("progress", event =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    const percentageString =
      Math.round((event.loaded / event.total) * 100) + "%";

    this.progressBar.style.width = percentageString;
    this.progressText.textContent = `Uploading (${percentageString})`;
  }

  createProgressBar(event) {
    this.progress = document.createElement("div");
    this.progress.classList.add("progress");

    this.progressBar = document.createElement("div");
    this.progressBar.classList.add("progress-bar");
    this.progressBar.style.width = "0%";
    this.progress.appendChild(this.progressBar);

    this.progressText = document.createElement("div");
    this.progressText.classList.add("form-hint", "text-muted", "mt-2");
    this.progressText.textContent = "Uploading (0%)";

    this.input.after(this.progressText);
    this.input.after(this.progress);
  }

  start() {
    this.uploading = true;

    this.upload = new DirectUpload(
      this.input.files[0],
      this.input.dataset.directUploadUrl,
      this
    );

    this.upload.create((error, blob) => {
      if (error) {
        this.uploading = false;

        window.alert(error);
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);

        hiddenField.name = this.input.name;
        this.element.appendChild(hiddenField);

        const form = this.input.form;
        this.input.remove();

        // Submit the form (again)
        form.removeEventListener("submit", this.onSubmit);
        form.submit();
      }
    });
  }
}
