import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['preferredSigningGenerationType', 'preferredSigningSendTypeInput']
  static values = { 'preferredSigningSendTypePerGenerationType': Object }

  update() {
    const enableGenerationType = this.preferredSigningSendTypePerGenerationTypeValue[this.preferredSigningGenerationTypeTarget.value]

    this.preferredSigningSendTypeInputTarget.tomselect.disable()

    if (enableGenerationType) {
      this.preferredSigningSendTypeInputTarget.tomselect.enable()
    }
  }
}
