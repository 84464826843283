import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['appliedIrs', 'appliedMarkup', 'appliedInterest', 'appliedRoe', 'appliedMonthlyPayment',
        'currentIrs', 'currentMarkup', 'currentInterest', 'currentRoe', 'currentMonthlyPayment']
    static values = {
        path: String
    }

    connect() {
        this.fetchValues()
    }

    fetchValues() {
        fetch(this.pathValue)
            .then(response => response.json())
            .then(json => {
                const applied = json.applied
                const current = json.current

                if (this.hasAppliedIrsTarget && this.hasAppliedMarkupTarget) {
                    this.appliedIrsTarget.innerHTML = applied.irs.decorated
                    this.appliedMarkupTarget.innerHTML = applied.markup.decorated
                }

                this.appliedInterestTarget.innerHTML = applied.interest.decorated
                this.appliedRoeTarget.innerHTML = applied.roe.decorated
                this.appliedMonthlyPaymentTarget.innerHTML = applied.monthly_payment.decorated

                if (this.hasCurrentIrsTarget && this.hasCurrentMarkupTarget) {
                    this.currentIrsTarget.innerHTML = current.irs.decorated
                    this.currentMarkupTarget.innerHTML = current.markup.decorated
                }

                this.currentInterestTarget.innerHTML = current.interest.decorated
                this.currentRoeTarget.innerHTML = current.roe.decorated
                this.currentMonthlyPaymentTarget.innerHTML = current.monthly_payment.decorated
            })
    }
}
