import { Controller } from 'stimulus'

// Connects to data-controller="change-recovery-process-status"
export default class extends Controller {
  static targets = ["recoveryProcessStatus", "recoveryProcessObjectLocationFields"]

  connect() {
    const boundShowHideLocation = this.showHideLocation.bind(this)
    boundShowHideLocation();
  }

  showHideLocation() {
    if (this.recoveryProcessStatusTarget.value === 'in_stock') {
      this.recoveryProcessObjectLocationFieldsTarget.classList.remove('hidden')
    } else {
      this.recoveryProcessObjectLocationFieldsTarget.classList.add('hidden')
    }
  }
}
