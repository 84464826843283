import { Controller } from 'stimulus'
import { csrfToken } from "./mixins/csrf_token"

export default class extends Controller {
    static targets = [
        'editContent', 'previewContent', 'variablesContent',
        'editButton', 'previewButton', 'variablesButton',
        'editTab', 'previewTab', 'variablesTab'
    ]

    static values = {
        form: String,
        previewPath: String,
    }

    connect() {
        csrfToken(this)
    }

    showEditTab(e) {
        e.preventDefault()
        this.previewButtonTarget.querySelector('a').classList.remove('active')
        this.editButtonTarget.querySelector('a').classList.add('active')
        if (this.hasvariablesButtonTarget) {
            this.variablesButtonTarget.querySelector('a').classList.remove('active')
        }

        this.previewTabTarget.classList.remove('active')
        this.editTabTarget.classList.add('active')
        if (this.hasVariablesTabTarget) {
            this.variablesTabTarget.classList.remove('active')
        }
    }

    showPreviewTab(e) {
        e.preventDefault()
        this.updatePreview()

        this.previewButtonTarget.querySelector('a').classList.add('active')
        this.editButtonTarget.querySelector('a').classList.remove('active')
        if (this.hasvariablesButtonTarget) {
            this.variablesButtonTarget.querySelector('a').classList.remove('active')
        }

        this.previewTabTarget.classList.add('active')
        this.editTabTarget.classList.remove('active')
        if (this.hasVariablesTabTarget) {
            this.variablesTabTarget.classList.remove('active')
        }
    }

    showVariablesTab(e) {
        e.preventDefault()
        this.previewButtonTarget.querySelector('a').classList.remove('active')
        this.editButtonTarget.querySelector('a').classList.remove('active')
        if (this.hasvariablesButtonTarget) {
            this.variablesButtonTarget.querySelector('a').classList.add('active')
        }

        this.previewTabTarget.classList.remove('active')
        this.editTabTarget.classList.remove('active')
        if (this.hasVariablesTabTarget) {
            this.variablesTabTarget.classList.add('active')
        }
    }

    updatePreview() {
        if (this.editContentTarget.value) {
            fetch(this.previewPathValue, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': this.csrfToken
                },
                body: this.formData
            })
                .then(response => response.json())
                .then(result => {
                    this.previewData(result.html)
                })
                .catch(_result => {
                    this.previewData('Er was een onverwachte fout bij het tonen van het voorbeeld')
                })
        } else {
            this.previewData('')
        }
    }

    previewData(data) {
        this.previewContentTarget.innerHTML = data
    }

    get formData() {
        if (this.previewPathValue === '/markdown_preview') {
            let formData = new FormData()
            formData.append('markdown', this.editContentTarget.value)
            return formData
        } else {
            const form = document.getElementById(this.formValue)
            return new FormData(form);
        }
    }
}
