import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'field']

  updateField(event) {
    event.preventDefault()

    this.fieldTarget.value = this.linkTarget.dataset.value

    // Ensures that listeners on the field e.g. other Stimulus controllers
    // are aware that there's input
    const newEvent = new Event('input', { "bubbles": true, "cancelable": false });
    this.fieldTarget.dispatchEvent(newEvent)
  }
}
