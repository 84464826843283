import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ["calculated_roe"]
    static values = {
        path: String
    }

    connect() {
        this.fetchCalculatedRoe()
    }

    fetchCalculatedRoe() {
        fetch(this.pathValue)
            .then(response => response.json())
            .then(json => this.element.innerHTML = json.decorated_value)
    }
}
