import {Controller} from 'stimulus'
import {csrfToken} from "./mixins/csrf_token";

export default class extends Controller {
    static targets = ['export', 'loader']
    static values = {
        url: String,
        id: Number
    }

    connect() {
        csrfToken(this)
    }

    export(event) {
        event.preventDefault();

        if (this.urlValue === '') {
            console.error('Impossible to export interest rate, no fetch url provided. Did you specify the url value?')
            return
        }

        this.loaderTarget.classList.remove('hidden')
        this.exportTarget.classList.add('hidden')

        fetch(this.urlValue, {
            method: 'POST', headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': this.csrfToken,
            }
        })
            .then(async response => {
                this.loaderTarget.classList.add('hidden')
                this.exportTarget.classList.remove('hidden')

                if (response.status >= 200 && response.status <= 299) {
                    this.dispatch('export', {detail: {id: this.idValue}})

                    return response.json()
                } else {
                    let json = await response.json();
                    throw Error(json.message)
                }
            })
            .catch(error => {
                alert(error)
            })
    }
}
