import { Controller } from "stimulus";

// Use this Stimulus controller to toggle an element using 'toggleElement' based on the
// checked value of 'checkbox'. Controller has optional feature:
//   1. You can show and hide the element using the 'toggleVisualElement'
export default class extends Controller {
  static targets = ['checkbox', 'toggleElement', 'toggleVisualElement']

  connect() {
    if (this.checkboxTarget.checked) {
      this.toggle()
    }
  }

  toggle() {
    this.toggleElementTarget.disabled = !this.checkboxTarget.checked

    if (this.hasToggleVisualElementTarget) {
      this.toggleVisualElementTarget.classList.toggle('hidden')
    }
  }
}
