import {Controller} from 'stimulus'
import {csrfToken} from "./mixins/csrf_token";

export default class extends Controller {
    static targets = ['output', 'loader', 'equalStatus', 'notEqualStatus']
    static values = {
        url: String,
        id: Number
    }

    connect() {
        csrfToken(this)
        this.fetchInterest();
    }

    fetchInterest() {
        if (this.urlValue === '') {
            console.error('Impossible to fetch interest rate, no fetch url provided. Did you specify the url value?')
            return
        }

        this.loaderTargets.forEach(loader => loader.classList.remove('hidden'))

        this.outputTarget.innerHTML = ''

        this.equalStatusTarget.classList.toggle('hidden', true)
        this.notEqualStatusTarget.classList.toggle('hidden', true)

        fetch(this.urlValue, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
            .then(async response => {
                this.loaderTargets.forEach(loader => loader.classList.add('hidden'))

                if (response.ok) {
                    return response.json()
                } else {
                    let json = await response.json();
                    throw Error(json.message)
                }
            })
            .then(json => {
                this.outputTarget.innerHTML = json.decorated_interest_rate
                this.equalStatusTarget.classList.toggle('hidden', !json.equal)
                this.notEqualStatusTarget.classList.toggle('hidden', json.equal)
            })
            .catch(error => {
                this.outputTarget.innerHTML = error
                this.notEqualStatusTarget.classList.toggle('hidden', false)
            })
    }

    refreshInterest({ detail: { id } }) {
        if (id !== this.idValue) {
            return
        }

        this.fetchInterest();
    }
}
