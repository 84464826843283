import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'valueField', 'valueOfPercentageField']

  static values = {
    delimiter: String,
    separator: String
  }

  updateField(event) {
    event.preventDefault()

    const currentValue = this.valueFieldTarget.value

    if (!currentValue) return

    const parsedCurrentValue = this.parseValueFromHumanMoneyFormat(currentValue)
    const percentage = event.params.percentage

    if (!percentage && percentage !== 0) return

    const valueOfPercentage = percentage === 0 ? 0 : parsedCurrentValue * (percentage / 100)
    const formattedValueOfPercentage = this.formatValueToHumanMoneyFormat(valueOfPercentage)

    this.valueOfPercentageFieldTarget.value = formattedValueOfPercentage

    // Ensures that listeners on the field e.g. other Stimulus controllers or
    // legacy JS components are aware that there's a change
    const newEvent = new Event('change', { "bubbles": true, "cancelable": false });
    this.valueOfPercentageFieldTarget.dispatchEvent(newEvent)
  }

  parseValueFromHumanMoneyFormat(value) {
    const [major, minor] = value.replace(this.delimiterValue, '').split(this.separatorValue)

    if (minor) {
      return parseFloat([major, minor].join('.'))
    } else {
      return parseFloat(major)
    }
  }

  formatValueToHumanMoneyFormat(value) {
    return this.roundFloat(value).toString().replace('.', this.separatorValue)
  }

  roundFloat(float) {
    return Math.round((float + Number.EPSILON) * 100) / 100
  }
}
