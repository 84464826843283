import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    super.connect();
    this.initializeTomSelect();
  }

  disconnect() {
    super.disconnect();
    this.destroyTomSelect()
  }

  redirect(event) {
    if (event.target.value != '') Turbo.visit(event.target.value)
  }

  initializeTomSelect() {
    if (!this.element) return

    this.select = new TomSelect(this.element,{ onDropdownOpen: function () { this.clear() } })
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy()
    }
  }
}
